import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import AvtiveArrowSvg from "../assets/svgs/AvtiveArrowSvg";
import BlackArrowDown from "../assets/svgs/BlackArrowDown";
import { styles } from "../Raferal/Raferal.js";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { months } from "../../data/calenderData";
import { useForm } from "react-hook-form";
import Loader from "react-loader-spinner";
import axios from "../../axios";

export const SelectSx = {
  fontSize: "16px",
  color: "#3A3A3A",
  fontFamily: "Poppins",
  fontWeight: 400,
};
const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: "200px !important",
  },
}));
const ModalContent = ({ infusionName, handleClose }) => {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  const classes = useStyles();
  const [activeSelect, setActiveSelect] = useState("");
  const [dayState, setDayState] = useState(date);
  const [days, setDays] = useState([]);
  const [yearState, setYearState] = useState(year);
  const [monthState, setMonthState] = useState(month);
  const [selectedGender, setSelectedGender] = useState("female");
  const [loader, setLoader] = React.useState(false);

  function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }
  const daysInCurrentMonth = getDaysInMonth(yearState, monthState);
  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }
  useEffect(() => {
    setDays(range(1, daysInCurrentMonth));
  }, [dayState, monthState]);

  useEffect(() => {
    setDayState(monthState === month ? date : 1);
  }, [monthState]);
  function handleCalenderDay(event) {
    setDayState(event.target.value);
  }
  function handleCalenderMonth(event) {
    setMonthState(event.target.value);
  }
  const futureMonths = months;
  function handleCalenderYear(event) {
    setYearState(event.target.value);
  }
  const currentYear = new Date().getFullYear();
  const startYear = 1980;
  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, i) => startYear + i
  );
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const patientName = watch("patientName");
  const phoneNumber = watch("phoneNumber");
  const emergencyPhoneNumber = watch("emergencyPhoneNumber");
  const email = watch("email");
  const address = watch("address");

  const formattedDay = dayState.toString().padStart(2, "0");
  const formattedMonth = monthState.toString().padStart(2, "0");
  const formattedDate = `${yearState}-${formattedMonth}-${formattedDay} 00:00:00`;
  const payload = {
    Infusion_name: infusionName,
    Patient_name: patientName,
    Phone_number: phoneNumber,
    Emergency_number: emergencyPhoneNumber,
    Email: email,
    Address: address,
    Gender: selectedGender,
    DOB: formattedDate,
  };
  const onSubmit = () => {
    setLoader(true);
    axios
      .post(`/api/write/Wellness_Form`, payload)
      .then((response) => {
        console.log(response.data);
        setLoader(false);
        handleClose();
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  };

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4} mt={2}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Box
            sx={{
              maxWidth: "100%",
            }}
          >
            <TextField
              fullWidth
              label="Wellness Infusion Name"
              id="fullWidth"
              value={infusionName}
            />
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Box
            sx={{
              maxWidth: "100%",
            }}
          >
            <TextField
              fullWidth
              label="Patient Name"
              id="fullWidth"
              {...register("patientName", {
                required: "Patient Name is required",
                validate: {
                  noNumbers: (value) =>
                    !/\d/.test(value) || "Patient Name cannot contain numbers",
                },
              })}
            />
            {errors.patientName && (
              <span style={{ color: "red" }}>{errors.patientName.message}</span>
            )}
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Box
            sx={{
              maxWidth: "100%",
            }}
          >
            <TextField
              fullWidth
              label="Phone Number"
              id="fullWidth"
              {...register("phoneNumber", {
                required: "Phone Number is required",
                pattern: {
                  value: /^\d{10}$/,
                  message: "Phone Number must be valid 10 digits",
                },
              })}
            />
            {errors.phoneNumber && (
              <span style={{ color: "red" }}>
                {errors?.phoneNumber?.message}
              </span>
            )}
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Box
            sx={{
              maxWidth: "100%",
              // marginBottom: "2%",
            }}
          >
            <TextField
              fullWidth
              label="Emergency Contact Number"
              id="fullWidth"
              {...register("emergencyPhoneNumber", {
                required: "Phone Number is required",
                pattern: {
                  value: /^\d{10}$/,
                  message: "Phone Number must be valid 10 digits",
                },
              })}
            />
            {errors.emergencyPhoneNumber && (
              <span style={{ color: "red" }}>
                {errors?.emergencyPhoneNumber?.message}
              </span>
            )}
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography>Date of Birth</Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{ display: { lg: "flex", md: "flex", sm: "flex", xs: "none" } }}
        >
          <Typography>Gender</Typography>
        </Grid>
        <Grid item lg={1.75} md={1.75} sm={1.75} xs={12}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel sx={SelectSx} id="demo-simple-select-helper-label">
              Date
            </InputLabel>
            <Select
              IconComponent={(props) =>
                activeSelect === "five" ? (
                  <AvtiveArrowSvg {...props} />
                ) : (
                  <BlackArrowDown {...props} />
                )
              }
              onFocus={() => setActiveSelect("five")}
              onBlur={() => setActiveSelect("")}
              sx={styles.select}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              name="role"
              label="Date"
              fullWidth
              value={dayState}
              displayEmpty
              onChange={handleCalenderDay}
              MenuProps={{
                classes: {
                  paper: classes.menuPaper,
                },
              }}
            >
              {days?.map((day, i) => (
                <MenuItem
                  value={day}
                  key={i}
                  onMouseEnter={(e) => {
                    e.target.style.color = "white";
                    e.target.style.backgroundColor = "#25ABDF";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.color = "#3A3A3A";
                    e.target.style.backgroundColor = "#FFFFFF";
                  }}
                >
                  {day}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={2.5} md={2.5} sm={2.5} xs={12}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel sx={SelectSx} id="demo-simple-select-helper-label">
              Month
            </InputLabel>
            <Select
              IconComponent={(props) =>
                activeSelect === "six" ? (
                  <AvtiveArrowSvg {...props} />
                ) : (
                  <BlackArrowDown {...props} />
                )
              }
              onFocus={() => setActiveSelect("six")}
              onBlur={() => setActiveSelect("")}
              sx={styles.select}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              name="role"
              label="Month"
              fullWidth
              value={monthState}
              displayEmpty
              onChange={handleCalenderMonth}
              MenuProps={{
                classes: { paper: classes.menuPaper },
              }}
            >
              {futureMonths.map((month, i) => {
                return (
                  <MenuItem
                    value={
                      Math.abs(futureMonths.length - months.length) + i + 1
                    }
                    key={i}
                    onMouseEnter={(e) => {
                      e.target.style.color = "white";
                      e.target.style.backgroundColor = "#25ABDF";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = "#3A3A3A";
                      e.target.style.backgroundColor = "#FFFFFF";
                    }}
                  >
                    {month}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={1.75} md={1.75} sm={1.75} xs={12}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel sx={SelectSx} id="demo-simple-select-helper-label">
              Year
            </InputLabel>
            <Select
              IconComponent={(props) =>
                activeSelect === "seven" ? (
                  <AvtiveArrowSvg {...props} />
                ) : (
                  <BlackArrowDown {...props} />
                )
              }
              onFocus={() => setActiveSelect("seven")}
              onBlur={() => setActiveSelect("")}
              sx={styles.select}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              name="role"
              label="Year"
              fullWidth
              value={yearState}
              displayEmpty
              onChange={handleCalenderYear}
              MenuProps={{
                classes: { paper: classes.menuPaper },
              }}
            >
              {years.map((year) => (
                <MenuItem
                  value={year}
                  key={year}
                  onMouseEnter={(e) => {
                    e.target.style.color = "white";
                    e.target.style.backgroundColor = "#25ABDF";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.color = "#3A3A3A";
                    e.target.style.backgroundColor = "#FFFFFF";
                  }}
                >
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{
            display: { lg: "none", md: "none", sm: "none", xs: "flex" },
            marginBottom: "-10%",
          }}
        >
          <Typography>Gender</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              onChange={handleGenderChange}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
              }}
            >
              <FormControlLabel
                value="Female"
                control={<Radio />}
                label="Female"
              />
              <FormControlLabel value="Male" control={<Radio />} label="Male" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Box
            sx={{
              maxWidth: "100%",
            }}
          >
            <TextField
              fullWidth
              label="Email"
              id="fullWidth"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Invalid email address",
                },
              })}
            />
            {errors.email && (
              <span style={{ color: "red" }}>{errors.email.message}</span>
            )}
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Box
            sx={{
              maxWidth: "100%",
            }}
          >
            <TextField
              fullWidth
              label="Address"
              id="fullWidth"
              {...register("address", {
                required: "Address field is required",
              })}
            />
            {errors.address && (
              <span style={{ color: "red" }}>{errors.address.message}</span>
            )}
          </Box>
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={12}>
          {loader === false && (
            <button className="SubmitBtn" type="submit">
              Submit
            </button>
          )}
          {loader === true && (
            <Loader type="Puff" color="#003b7d" height={50} width={50}></Loader>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default ModalContent;
