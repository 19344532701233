import React, { useEffect, useRef, useState } from "react";
import "../OurServices/ourservices.css";
import leftarr from "../assets/images/our.png";

import { useNavigate } from "react-router-dom";
const data = [
  {
    title: "Ambulatory Infusion Center",
    description: "Managed by clinicians & RNs to provide infusion therapies.",
    link: "/services/ambulatory",
  },
  {
    title: "Home Infusion",
    description: "Infusion care in the comfort of the patient's home.",
    link: "/services/home-infusion",
  },
  { title: "Compounding", description: "...", link: "/services/compounding" },
  {
    title: "Drugs / Infusions",
    description:
      "Custom-blended IVs that optimize patient well-being & health.",
    link: "/",
  },
];
const OurServices = () => {
  let navigate = useNavigate();
  const navigate_ = (url) => {
    navigate(url);
  };
  const targetElementRef = useRef(null);
  useEffect(() => {
    if (targetElementRef.current) {
      targetElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  const [showFullText, setShowFullText] = useState(false);
  const text =
    "Injectables & swallowable pills compounded in a sterile environment.";

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };

  const displayText =
    text.length > 55 && !showFullText ? text.substring(0, 55) + "..." : text;
  const showToggleLink = text.length > 55;
  // Nothing changed
  return (
    <>
      <div className="ourservices_main">
        <div className="ourservices_heading">
          <p className="ourservices_title">Our Services </p>
          <p className="ourservices_para">
            We specialize in providing infusion care to patients and ensuring
            that they stay on their prescribed regimen throughout the treatment.
          </p>
          <div className="ourservices_btn_div">
            <button
              onClick={() => navigate_("/appointment")}
              className="ourservices_btn"
            >
              Book An Appointment
            </button>
          </div>
        </div>
      </div>
      <div
        className="ourservices_data_main"
        id="targetElement"
        ref={targetElementRef}
      >
        <div className="ourservices_text">
          <p className="ourservices_text_heading">
            Personalized Care For Chronic & Acute Conditions
          </p>
          <p className="ourservices_text_para">
            Patient care is our #1 priority. Our specialists provide guidance
            and care to every patient through personalized infusion therapy
            treatments.
          </p>
        </div>

        <div className="servies_card">
          <div className="servies_card_data">
            <p className="services_card_heading">Ambulatory Infusion Center</p>
            <p className="services_card_para">
              Managed by clinicians & RNs to provide infusion therapies.{" "}
            </p>
            <button
              onClick={() => navigate_("/services/ambulatory")}
              className="services_card_btn"
            >
              More Info{" "}
              <span>
                <img src={leftarr} />
              </span>{" "}
              <span className="ms-1"></span>
            </button>
          </div>
          <div className="servies_card_data">
            <p className="services_card_heading">Home Infusion</p>
            <p className="services_card_para">
              Infusion care in the comfort of the patient's home.
            </p>
            <button
              onClick={() => navigate_("/services/home-infusion")}
              className="services_card_btn"
            >
              More Info{" "}
              <span>
                <img src={leftarr} />
              </span>
              <span className="ms-1"></span>
            </button>
          </div>
        </div>
        <div className="servies_card" style={{ marginBottom: "2%" }}>
          <div className="servies_card_data">
            <p className="services_card_heading">Compounding</p>
            <p className="services_card_para">
              {displayText}
              {showToggleLink && (
                <span
                  onClick={toggleFullText}
                  style={{ cursor: "pointer", color: "#25abdf" }}
                >
                  {showFullText ? " See less" : " See more"}
                </span>
              )}
            </p>
            <button
              onClick={() => navigate_("/services/compounding")}
              className="services_card_btn"
            >
              More Info{" "}
              <span>
                <img src={leftarr} />
              </span>{" "}
              <span className="ms-1"></span>
            </button>
          </div>
          {/* <div className="servies_card_data">
            <p className="services_card_heading">Drugs / Infusions</p>
            <p className="services_card_para">
              Custom-blended IVs that optimize patient well-being & health.
            </p>
            <button
              onClick={() => navigate_("/")}
              className="services_card_btn"
            >
              More Info{" "}
              <span>
                <img src={leftarr} />
              </span>{" "}
              <span className="ms-1"></span>
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default OurServices;
