import React, { useState } from "react";
import "./AppointmentForm.css";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Providers from "../assets/svgs/Providers";
import User from "../assets/svgs/BlackUser";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ReCAPTCHA from "react-google-recaptcha";
import { Col } from "react-bootstrap";
import { months } from "../../data/calenderData";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import ValidateTextField from "../ValidateTextField/ValidateTextField";
import BackArrowBlkSvg from "../../Componenets/assets/svgs/BackArrowBlkSvg";
import drPic from "../../Componenets/assets/images/drdialog.png";
import patientPic from "../../Componenets/assets/images/patientdialog.png";
import { styles } from "../Raferal/Raferal.js";
import AvtiveArrowSvg from "../assets/svgs/AvtiveArrowSvg";
import BlackArrowDown from "../assets/svgs/BlackArrowDown";
import { addDataApi } from "../../api";
import Loader from "react-loader-spinner";
import moment from "moment";
import successWhiteTick from "../assets/images/successWhiteTick.png";
import CloseIcon from "@mui/icons-material/Close";

export const SelectSx = {
  fontSize: "16px",
  color: "#3A3A3A",
  fontFamily: "Poppins",
  fontWeight: 400,
};

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: "200px !important",
  },
}));

const AppointmentForm = ({ patient }) => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 ? true : false
  );
  const [isSm, setIsSm] = useState(window.innerWidth < 500 ? true : false);
  const [isXsm, setIsXsm] = useState(window.innerWidth < 420 ? true : false);
  const [activeSelect, setActiveSelect] = useState("");
  const updateDimensions = () => {
    setIsMobile(window.innerWidth < 768 ? true : false);
    setIsSm(window.innerWidth < 500 ? true : false);
    setIsXsm(window.innerWidth < 420 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isMobile, isSm, isXsm]);
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  const futureMonths = months.filter((_, i) => i + 1 >= month);
  const classes = useStyles();
  const [step1, setStep1] = useState(false);
  const [activeNav, setActiveNav] = useState(1);
  const [open, setOpen] = useState(true);
  const [dayState, setDayState] = useState(date);
  const [monthState, setMonthState] = useState(month);
  const [yearState, setYearState] = useState(year);
  const [catchaState, setCatchaState] = useState("");
  const [days, setDays] = useState([]);

  let userTypes = activeNav === 1 ? "Patient" : "Provider";
  const [values, setValues] = React.useState({
    user_type: userTypes,
    name: " ",
    phone_no: " ",
    email: " ",
    treatment_type: "none",
    day_for_infusion: "none",
    time_for_infusion: "none",
    practice: " ",
    provider_id: " ",
    post_code: " ",
    patient_name: " ",
    patient_phone_no: " ",
    patient_email: " ",
  });

  const [loader, setLoader] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorsProvider, setErrorsProvider] = useState({});
  const [errorName, setErrorName] = useState("");
  const [errorPatientName, setErrorPatientName] = useState("");
  const [errorPractice, setErrorPractice] = useState("");
  const [errorProvider, setErrorProvider] = useState("");
  const [errorPostCode, setErrorPostCode] = useState("");

  useEffect(() => {
    if (patient === "patients") {
      setActiveNav(1);
    } else if (patient === "provider") {
      setActiveNav(2);
    }
  }, [patient]);
  function onChangeCatcha(value) {
    setCatchaState(value);
  }
  function changeHandle(value) {
    setActiveNav(value);
    setStep1(false);
  }
  function handleCalenderDay(event) {
    setDayState(event.target.value);
  }
  function handleCalenderMonth(event) {
    setMonthState(event.target.value);
  }
  function handleCalenderYear(event) {
    setYearState(event.target.value);
  }
  const handleChange1 = (value) => {
    setActiveNav(value);
    setOpen(false);
  };
  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }

  const handleFields = (event) => {
    const value = event.target.value;
    setValues({
      ...values,
      user_type: userTypes,
      [event.target.name]:
        value === "" ? " " : value.length >= 1 ? value.trim() : value.trim(),
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    let payload = { ...values };
    payload["post_code"] = parseInt(payload["post_code"]);
    payload["status"] = "Pending";

    setLoader(true);
    try {
      const res = await addDataApi("Appointment", payload);
      if (res.status === 200) {
        setLoader(false);
        setValues({
          ...values,
          user_type: userTypes,
          name: " ",
          phone_no: " ",
          email: " ",
          treatment_type: "none",
          day_for_infusion: {},
          time_for_infusion: "none",
          practice: " ",
          post_code: " ",
          patient_name: " ",
          patient_phone_no: " ",
          patient_email: " ",
        });
        setDayState(date);
        setMonthState(month);
        setYearState(year);
        setFormSuccess(true);
        alert("Appointment done....");
      }
    } catch (err) {
      setLoader(false);
      if (err.response) {
      }
    }
  };

  const checkNumbers = (event) => {
    let errors_ = { ...errors };
    if (!/[0-9]/.test(event.key)) {
      // event.preventDefault();
      errors_["numbers"] = "Enter Numbers Only";
    } else {
      delete errors_["numbers"];
    }
    setErrors(errors_);
  };

  const nextButtonDisable = () => {
    if (
      values.user_type === "none" ||
      values.name === " " ||
      values.phone_no === " " ||
      values.email === " " ||
      values.practice === " " ||
      values.post_code === " " ||
      values.provider_id === " " ||
      errors.email ||
      errors.phone
    ) {
      return true;
    }
    return false;
  };

  const isButtonDisable = () => {
    if (
      values.user_type === "none" ||
      values.name === " " ||
      values.phone_no === " " ||
      values.email === " " ||
      values.treatment_type === "none" ||
      values.day_for_infusion === "none" ||
      values.time_for_infusion === "none" ||
      values.practice === " " ||
      values.provider_id === " " ||
      values.post_code === " " ||
      values.patient_name === " " ||
      values.patient_phone_no === " " ||
      values.patient_email === " " ||
      errors.email ||
      errors.phone
    ) {
      return true;
    }
    return false;
  };

  const patientButtonDisable = () => {
    if (
      values.user_type === "none" ||
      values.treatment_type === "none" ||
      values.day_for_infusion === "none" ||
      values.time_for_infusion === "none" ||
      values.patient_name === " " ||
      values.patient_phone_no === " " ||
      values.patient_email === " " ||
      values.name === " " ||
      errors.email ||
      errors.phone
    ) {
      return true;
    }
    return false;
  };

  const validatePhone = (e) => {
    const regexPhoneNumber =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  
    let errors_ = { ...errors };
  
    // Check if the input has more than 10 digits
    const phoneNumber = e.target.value.replace(/\D/g, ''); // Remove non-digit characters to count digits
    if (phoneNumber.length > 10) {
      errors_["phone"] = "Phone number cannot exceed 10 digits.";
    } else if (!e.target.value.match(regexPhoneNumber)) {
      errors_["phone"] = "Invalid phone number format.";
    } else {
      delete errors_["phone"];
    }
  
    setErrors(errors_);
  };
  
  const validatePhone2 = (e) => {
    const regexPhoneNumber =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  
    let errors_ = { ...errors };
  
    // Check if the input has more than 10 digits
    const phoneNumber = e.target.value.replace(/\D/g, ''); // Remove non-digit characters to count digits
    if (phoneNumber.length > 10) {
      errors_["phone"] = "Phone number cannot exceed 10 digits.";
    } else if (!e.target.value.match(regexPhoneNumber)) {
      errors_["phone"] = "Invalid phone number format.";
    } else {
      delete errors_["phone"];
    }
  
    setErrorsProvider(errors_);
  };
  

  const validateEmail = (e) => {
    let errors_ = { ...errors };
    let errorEmail = false;
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(e.target.value)) {
      errors_["email"] = "Invalid email address.";
      errorEmail = true;
    }
    if (errorEmail === false) {
      delete errors_["email"];
    }
    setErrors(errors_);
  };
  const validateEmail2 = (e) => {
    let errors_ = { ...errors };
    let errorEmail = false;
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(e.target.value)) {
      errors_["email"] = "Invalid email address.";
      errorEmail = true;
    }
    if (errorEmail === false) {
      delete errors_["email"];
    }
    setErrorsProvider(errors_);
  };

  const dateFunction = () => {
    const format = "YYYY-MM-DD";
    let currentDate = new Date(yearState, monthState - 1, dayState);
    let currentDate1 = moment(currentDate).format(format);
    return currentDate1;
  };
  function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }
  const daysInCurrentMonth = getDaysInMonth(yearState, monthState);

  useEffect(() => {
    setDays(range(monthState === month ? date : 1, daysInCurrentMonth));
    setValues({
      ...values,
      ["day_for_infusion"]: dateFunction(),
    });
  }, [dayState, monthState]);
  useEffect(() => {
    setDayState(monthState === month ? date : 1);
  }, [monthState]);

  const continueFunction = () => {
    setFormSuccess(false);
    setOpen(true);
  };
  // Add an event listener for scroll events
  window.addEventListener("scroll", handleScroll);

  function handleScroll() {
    // Get the form element and its dimensions
    const formElement = document.querySelector(".form-data");
    const formRect = formElement.getBoundingClientRect();

    // Calculate the vertical position of the form relative to the viewport
    const formTop = formRect.top;
    const formHeight = formRect.height;
    const windowHeight = window.innerHeight;
    const formCenter = formTop + formHeight / 2;

    // Calculate the vertical position where the form should start scrolling
    const scrollThreshold = windowHeight / 2;

    // Enable scrolling if the form has reached the center of the viewport
    if (formCenter < scrollThreshold) {
      formElement.style.overflowY = "scroll";
    } else {
      formElement.style.overflowY = "hidden";
    }
  }
  const handleValidation = () => {
    if (values.name.trim() === "") {
      setErrorName("Field cannot be empty");
    } else if (/\d/.test(values.name)) {
      setErrorName("Field cannot contain numbers");
    } else {
      setErrorName("");
    }
  };
  const handleValidationPatient = () => {
    if (values.patient_name.trim() === "") {
      setErrorPatientName("Field cannot be empty");
    } else if (/\d/.test(values.patient_name)) {
      setErrorPatientName("Field cannot contain numbers");
    } else {
      setErrorPatientName("");
    }
  };
  const handlePracticeValidation = () => {
    if (values.practice.trim() === "") {
      setErrorPractice("Field cannot be empty");
    } else {
      setErrorPractice("");
    }
  };
  const handleProviderValidation = () => {
    if (values.provider_id.trim() === "") {
      setErrorProvider("Field cannot be empty");
    } else {
      setErrorProvider("");
    }
  };
  const handlePostValidation = () => {
    if (values.post_code.trim() === "") {
      setErrorPostCode("Field cannot be empty");
    } else {
      setErrorPostCode("");
    }
  };
  return (
    <>
      {patient !== "patients" && patient !== "provider" && (
        <Dialog
          open={open}
          PaperProps={{
            sx: {
              maxWidth: isSm ? "90vw" : "433px",
              width: isSm ? "90vw" : "433px",
              height: isSm ? "253px" : "310px",
              maxHeight: 600,
              borderRadius: "12px",
              padding: "15px",
              boxSizing: "border-box",
              position: "relative",
            },
          }}
        >
          <CloseIcon
            sx={{
              position: "absolute",
              right: "7%",
              top: { lg: "10%", xs: "15%" },
              cursor: "pointer",
            }}
            onClick={() => setOpen(false)}
          />
          <DialogTitle
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "27px",
              color: "#3A3A3A",
            }}
          >
            I am a
          </DialogTitle>
          <DialogContent>
            <div className="d-flex justify-content-between ">
              <img
                src={drPic}
                alt="image1"
                className={
                  isXsm
                    ? "dialogImage-Xsm me-2"
                    : isSm
                    ? "dialog-img-Mobile me-1"
                    : "dialog-img-style"
                }
                onClick={() => handleChange1(2)}
              />
              <img
                src={patientPic}
                alt="image2"
                className={
                  isXsm
                    ? "dialogImage-Xsm"
                    : isSm
                    ? "dialog-img-Mobile "
                    : "dialog-img-style"
                }
                onClick={() => handleChange1(1)}
              />
            </div>
          </DialogContent>
        </Dialog>
      )}
      <div className="container">
        <div className="row my-5">
          <p className="app-top-text">
            Fill out the required details in the form below to book your
            appointment, and we will get back to you shortly.{" "}
          </p>
        </div>

        {formSuccess === true && (
          <Col
            md={12}
            className="d-flex justify-content-center align-items-center"
          >
            <div className="successWrapper">
              <div className="circleBlueDiv">
                <div>
                  <img src={successWhiteTick} alt="whiteTick" />
                </div>
              </div>
              <p className="successTitleText">Success</p>
              <p className="successsubTitleText">
                We'll get back to you shortly!{" "}
              </p>
              <button
                className="successBtnClass"
                onClick={() => continueFunction()}
              >
                Continue
              </button>
            </div>
          </Col>
        )}
        {formSuccess === false && (
          <div className="row">
            <div className="col-md-2 form-bar">
              {isMobile ? (
                <>
                  <div
                    className={
                      activeNav === 1
                        ? "form-sidebar app-active"
                        : "form-sidebar "
                    }
                    onClick={() => changeHandle(1)}
                  >
                    <div className="d-flex flex-column align-items-center">
                      <span className="form-icon">
                        {" "}
                        {activeNav === 1 ? (
                          <User stroke="white" />
                        ) : (
                          <User stroke="black" />
                        )}
                      </span>
                      <span>Patients</span>
                    </div>
                  </div>
                  <div
                    className={
                      activeNav === 2
                        ? "form-sidebar app-active"
                        : "form-sidebar "
                    }
                    onClick={() => changeHandle(2)}
                  >
                    <div className="d-flex flex-column align-items-center">
                      <span className="form-icon">
                        {activeNav === 2 ? (
                          <Providers stroke="white" />
                        ) : (
                          <Providers stroke="black" />
                        )}
                      </span>
                      <span>Providers</span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="form-sidebar" onClick={() => changeHandle(1)}>
                    <div className="d-flex align-items-center">
                      <div
                        className={
                          activeNav === 1
                            ? " sidesvg-div app-active"
                            : " sidesvg-div"
                        }
                      >
                        <span className="form-icon">
                          {" "}
                          {activeNav === 1 ? (
                            <User stroke="white" />
                          ) : (
                            <User stroke="black" />
                          )}
                        </span>
                      </div>
                      <div
                        className={
                          activeNav === 1
                            ? "sidetext-div appactivetext"
                            : " sidetext-div"
                        }
                      >
                        <span>Patients</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-sidebar" onClick={() => changeHandle(2)}>
                    <div className="d-flex align-items-center">
                      <div
                        className={
                          activeNav === 2
                            ? " sidesvg-div app-active"
                            : " sidesvg-div"
                        }
                      >
                        <span className="form-icon">
                          {activeNav === 2 ? (
                            <Providers stroke="white" />
                          ) : (
                            <Providers stroke="black" />
                          )}
                        </span>
                      </div>
                      <div
                        className={
                          activeNav === 2
                            ? "sidetext-div appactivetext"
                            : "sidetext-div"
                        }
                      >
                        <span>Providers</span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {step1 === false && activeNav !== 1 ? (
              <div className="col-md-10 form-data">
                {activeNav === 1 ? (
                  ""
                ) : (
                  <>
                    {" "}
                    <div className="my-3 d-flex">
                      <div className="hr-styling1 "></div>
                      <div className="hr-styling2"></div>
                    </div>
                    <p>Step 1 of 2</p>
                  </>
                )}

                <h2 className="AppTitle">Provider Details</h2>
                <div className="row">
                  <div className="col-12">
                    <ValidateTextField
                      label="Name*"
                      name="name"
                      value={values.name}
                      onChange={handleFields}
                      onBlur={handleValidation}
                    />
                    {errorName && (
                      <div
                        className="text-danger"
                        style={{ marginTop: "-15px" }}
                      >
                        {errorName}
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    <ValidateTextField
                      label="Phone Number*"
                      onBlur={validatePhone}
                      name="phone_no"
                      value={values.phone_no}
                      onChange={handleFields}
                    />
                     <p className="helper-para"><span>Note:</span> Reply STOP to opt-out </p>
                    <div className="text-danger" style={{ marginTop: "-15px" }}>
                      {errors.phone}
                    </div>
                   
                  </div>
                  <div className="col-12">
                    <ValidateTextField
                      label="Email*"
                      onBlur={validateEmail}
                      name="email"
                      value={values.email}
                      onChange={handleFields}
                    />
                    <div className="text-danger" style={{ marginTop: "-15px" }}>
                      {errors.email}
                    </div>
                  </div>
                  <div className="col-12">
                    <ValidateTextField
                      label="Practice*"
                      name="practice"
                      value={values.practice}
                      onChange={handleFields}
                      onBlur={handlePracticeValidation}
                    />
                    {errorPractice && (
                      <div
                        className="text-danger"
                        style={{ marginTop: "-15px" }}
                      >
                        {errorPractice}
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    <ValidateTextField
                      label="Provider ID*"
                      name="provider_id"
                      value={values.provider_id}
                      onChange={handleFields}
                      onBlur={handleProviderValidation}
                    />
                    {errorProvider && (
                      <div
                        className="text-danger"
                        style={{ marginTop: "-15px" }}
                      >
                        {errorProvider}
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    <ValidateTextField
                      label="Post Code*"
                      onKeyPress={checkNumbers}
                      name="post_code"
                      value={values.post_code}
                      onChange={handleFields}
                      onBlur={handlePostValidation}
                    />
                    {errorPostCode && (
                      <div
                        className="text-danger"
                        style={{ marginTop: "-15px" }}
                      >
                        {errorPostCode}
                      </div>
                    )}
                    {/* <div className="text-danger" style={{ marginTop: "-15px" }}>
                      {errors.numbers}
                    </div> */}
                  </div>
                </div>
                <div className="row ">
                  <div>
                    <button
                      className={
                        activeNav === 2
                          ? nextButtonDisable()
                            ? "disableStep1Btn"
                            : "Step1Btn"
                          : ""
                      }
                      // className="Step1Btn"
                      disabled={activeNav === 2 ? nextButtonDisable() : ""}
                      onClick={() => {
                        setStep1(!step1);
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-10 form-data">
                {activeNav === 1 ? (
                  ""
                ) : (
                  <>
                    {isMobile ? (
                      <div
                        className="mobileBackBtnDiv"
                        onClick={() => setStep1(false)}
                      >
                        <BackArrowBlkSvg stroke="#25ABDF" />
                      </div>
                    ) : (
                      <div
                        className="goBackBnt"
                        onClick={() => setStep1(false)}
                      >
                        <div className="backIconDiv">
                          <BackArrowBlkSvg stroke="#3A3A3A" />
                        </div>
                        <span className="goBackText">GoBack</span>
                      </div>
                    )}

                    <div className="hr-styling my-3"></div>
                    <div className="d-flex align-items-center">
                      <p>Step 2 of 2</p>
                    </div>
                  </>
                )}

                {activeNav === 2 ? (
                  <h2 className="AppTitle">Patient Details</h2>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-12">
                    <ValidateTextField
                      label="Name*"
                      name="patient_name"
                      value={values.patient_name}
                      onChange={handleFields}
                      onBlur={handleValidationPatient}
                    />
                    {errorPatientName && (
                      <div
                        className="text-danger"
                        style={{ marginTop: "-15px" }}
                      >
                        {errorPatientName}
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    <ValidateTextField
                      label="Phone Number*"
                      onBlur={validatePhone2}
                      name="patient_phone_no"
                      value={values.patient_phone_no}
                      onChange={handleFields}
                    />
                     <p className="helper-para"><span>Note:</span> Reply STOP to opt-out </p>
                    <div className="text-danger" style={{ marginTop: "-15px" }}>
                      {errorsProvider.phone}
                    </div>
                   
                  </div>
                  <div className="col-12">
                    <ValidateTextField
                      label="Email*"
                      onBlur={validateEmail2}
                      name="patient_email"
                      value={values.patient_email}
                      onChange={handleFields}
                    />
                    <div className="text-danger" style={{ marginTop: "-15px" }}>
                      {errorsProvider.email}
                    </div>
                  </div>
                  <div className="col-12">
                    <FormControl
                      sx={{ minWidth: 120, width: "100%", marginTop: "12px" }}
                    >
                      <InputLabel
                        sx={SelectSx}
                        id="demo-simple-select-helper-label"
                      >
                        Select Your Treatment Type*
                      </InputLabel>
                      <Select
                        IconComponent={(props) =>
                          activeSelect === "four" ? (
                            <AvtiveArrowSvg {...props} />
                          ) : (
                            <BlackArrowDown {...props} />
                          )
                        }
                        onFocus={() => setActiveSelect("four")}
                        onBlur={() => setActiveSelect("")}
                        sx={styles.select}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        name="treatment_type"
                        label="Select Your Treatment Type*"
                        value={values.treatment_type}
                        onChange={handleFields}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem
                          value={"none"}
                          onMouseEnter={(e) => {
                            e.target.style.color = "white";
                            e.target.style.backgroundColor = "#25ABDF";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.color = "#3A3A3A";
                            e.target.style.backgroundColor = "#FFFFFF";
                          }}
                        >
                          Select an option
                        </MenuItem>
                        <MenuItem
                          value={"Iron Infusions"}
                          onMouseEnter={(e) => {
                            e.target.style.color = "white";
                            e.target.style.backgroundColor = "#25ABDF";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.color = "#3A3A3A";
                            e.target.style.backgroundColor = "#FFFFFF";
                          }}
                        >
                          Iron Infusions
                        </MenuItem>
                        <MenuItem
                          value={"Antibiotic Infusions"}
                          onMouseEnter={(e) => {
                            e.target.style.color = "white";
                            e.target.style.backgroundColor = "#25ABDF";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.color = "#3A3A3A";
                            e.target.style.backgroundColor = "#FFFFFF";
                          }}
                        >
                          Antibiotic Infusions
                        </MenuItem>
                        <MenuItem
                          value={"Hydration & Medical Infusions"}
                          onMouseEnter={(e) => {
                            e.target.style.color = "white";
                            e.target.style.backgroundColor = "#25ABDF";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.color = "#3A3A3A";
                            e.target.style.backgroundColor = "#FFFFFF";
                          }}
                        >
                          Hydration & Medical Infusions
                        </MenuItem>
                        <MenuItem
                          value={"Immunity Boosting Infusions"}
                          onMouseEnter={(e) => {
                            e.target.style.color = "white";
                            e.target.style.backgroundColor = "#25ABDF";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.color = "#3A3A3A";
                            e.target.style.backgroundColor = "#FFFFFF";
                          }}
                        >
                          Immunity Boosting Infusions
                        </MenuItem>
                        <MenuItem
                          value={"Biologic Infusions"}
                          onMouseEnter={(e) => {
                            e.target.style.color = "white";
                            e.target.style.backgroundColor = "#25ABDF";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.color = "#3A3A3A";
                            e.target.style.backgroundColor = "#FFFFFF";
                          }}
                        >
                          Biologic Infusions
                        </MenuItem>
                        <MenuItem
                          value={"Other Infusions"}
                          onMouseEnter={(e) => {
                            e.target.style.color = "white";
                            e.target.style.backgroundColor = "#25ABDF";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.color = "#3A3A3A";
                            e.target.style.backgroundColor = "#FFFFFF";
                          }}
                        >
                          Other Infusions
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-md-12 col-12">
                    <p className="app-p-text">Preferred Day For Infusion*</p>
                    <div className="row gy-4 col-12">
                      <div className="col-md-3 col-3">
                        <FormControl sx={{ width: "100%" }}>
                          <InputLabel
                            sx={SelectSx}
                            id="demo-simple-select-helper-label"
                          >
                            Date
                          </InputLabel>
                          <Select
                            IconComponent={(props) =>
                              activeSelect === "five" ? (
                                <AvtiveArrowSvg {...props} />
                              ) : (
                                <BlackArrowDown {...props} />
                              )
                            }
                            onFocus={() => setActiveSelect("five")}
                            onBlur={() => setActiveSelect("")}
                            sx={styles.select}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            name="role"
                            label="Date"
                            fullWidth
                            value={dayState}
                            displayEmpty
                            onChange={handleCalenderDay}
                            MenuProps={{
                              classes: {
                                paper: classes.menuPaper,
                              },
                            }}
                          >
                            {days?.map((day, i) => (
                              <MenuItem
                                value={day}
                                key={i}
                                onMouseEnter={(e) => {
                                  e.target.style.color = "white";
                                  e.target.style.backgroundColor = "#25ABDF";
                                }}
                                onMouseLeave={(e) => {
                                  e.target.style.color = "#3A3A3A";
                                  e.target.style.backgroundColor = "#FFFFFF";
                                }}
                              >
                                {day}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-6 col-5">
                        <FormControl sx={{ width: "100%" }}>
                          <InputLabel
                            sx={SelectSx}
                            id="demo-simple-select-helper-label"
                          >
                            Month
                          </InputLabel>
                          <Select
                            IconComponent={(props) =>
                              activeSelect === "six" ? (
                                <AvtiveArrowSvg {...props} />
                              ) : (
                                <BlackArrowDown {...props} />
                              )
                            }
                            onFocus={() => setActiveSelect("six")}
                            onBlur={() => setActiveSelect("")}
                            sx={styles.select}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            name="role"
                            label="Month"
                            fullWidth
                            value={monthState}
                            displayEmpty
                            onChange={handleCalenderMonth}
                            MenuProps={{
                              classes: { paper: classes.menuPaper },
                            }}
                          >
                            {futureMonths.map((month, i) => {
                              return (
                                <MenuItem
                                  value={
                                    Math.abs(
                                      futureMonths.length - months.length
                                    ) +
                                    i +
                                    1
                                  }
                                  key={i}
                                  onMouseEnter={(e) => {
                                    e.target.style.color = "white";
                                    e.target.style.backgroundColor = "#25ABDF";
                                  }}
                                  onMouseLeave={(e) => {
                                    e.target.style.color = "#3A3A3A";
                                    e.target.style.backgroundColor = "#FFFFFF";
                                  }}
                                >
                                  {month}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-3 col-4">
                        <FormControl sx={{ width: "100%" }}>
                          <InputLabel
                            sx={SelectSx}
                            id="demo-simple-select-helper-label"
                          >
                            Year
                          </InputLabel>
                          <Select
                            IconComponent={(props) =>
                              activeSelect === "seven" ? (
                                <AvtiveArrowSvg {...props} />
                              ) : (
                                <BlackArrowDown {...props} />
                              )
                            }
                            onFocus={() => setActiveSelect("seven")}
                            onBlur={() => setActiveSelect("")}
                            sx={styles.select}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            name="role"
                            label="Year"
                            fullWidth
                            value={yearState}
                            displayEmpty
                            onChange={handleCalenderYear}
                            // disabled={true}
                            MenuProps={{
                              classes: { paper: classes.menuPaper },
                            }}
                          >
                            <MenuItem
                              value={year}
                              onMouseEnter={(e) => {
                                e.target.style.color = "white";
                                e.target.style.backgroundColor = "#25ABDF";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.color = "#3A3A3A";
                                e.target.style.backgroundColor = "#FFFFFF";
                              }}
                            >
                              {year}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-12 radio-div-style">
                    <div className="row ">
                      <p className="app-p-text mt-md-2">
                        {" "}
                        Preferred Time For Infusion*
                      </p>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="time_for_infusion"
                        className="radio-div"
                      >
                        <FormControlLabel
                          value="morning"
                          checked={values.time_for_infusion === "morning"}
                          onChange={handleFields}
                          className="radio-element "
                          control={<Radio size="small" />}
                          label="Morning"
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#3A3A3A",
                            },
                          }}
                        />
                        <FormControlLabel
                          value="afternoon"
                          checked={values.time_for_infusion === "afternoon"}
                          onChange={handleFields}
                          className="radio-element "
                          control={<Radio size="small" />}
                          label="Afternoon"
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#3A3A3A",
                            },
                          }}
                        />
                      </RadioGroup>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="my-3">
                    <ReCAPTCHA
                      sitekey="6Le1xMUhAAAAANzp9RplrXQr2a_7oOsYpxSIE-RS"
                      onChange={onChangeCatcha}
                    />
                  </div>
                  <div>
                    {loader === false && (
                      <button
                        className={
                          activeNav === 1
                            ? patientButtonDisable()
                              ? "disableContactBtn"
                              : "ContactBtn"
                            : isButtonDisable()
                            ? "disableContactBtn"
                            : "ContactBtn"
                        }
                        type="submit"
                        disabled={
                          activeNav === 1
                            ? patientButtonDisable()
                            : isButtonDisable()
                        }
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    )}
                    {loader === true && (
                      <Loader
                        type="Puff"
                        color="#003b7d"
                        height={50}
                        width={50}
                      ></Loader>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AppointmentForm;
