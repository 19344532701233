import React from "react";
import "../Raferal/raferal.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import "../Contact/contact.css";
import { Col, Form, Row } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ValidateTextField from "../ValidateTextField/ValidateTextField";
import { SxProps } from "@mui/material/styles";
import { SelectSx } from "../AppointmentForm/AppointmentForm";
import BlackArrowDown from "../assets/svgs/BlackArrowDown";
import AvtiveArrowSvg from "../assets/svgs/AvtiveArrowSvg";
import { useState } from "react";
import { useEffect } from "react";
import { addDataApi } from "../../api";
import Loader from "react-loader-spinner";
import successWhiteTick from "../assets/images/successWhiteTick.png";

export const styles: SxProps = {
  select: {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "#3A3A3A",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#25ABDF !important",
      color: "red",
    },
    ".MuiSvgIcon-root": {
      fill: "none !important",
      stroke: "#3A3A3A !important",
    },
    "&.Mui-focused  .MuiSvgIcon-root ": {
      fill: "none !important",
      stroke: "#25ABDF !important",
    },
  },
};

const Raferal = () => {
  const [activeSelect, setActiveSelect] = useState("");
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 ? true : false
  );
  const updateDimensions = () => {
    setIsMobile(window.innerWidth < 768 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isMobile]);

  const [values, setValues] = React.useState({
    user_type: "Provider",
    dr_first_name: " ",
    dr_last_name: " ",
    dr_phone_no: " ",
    dr_email: " ",
    job_role: "none",
    job_title: " ",
    contact_preservence: "none",
    services_interested: "none",
    patient_name: " ",
    hospital_name: " ",
    hospital_address: " ",
    hospital_city: " ",
    hospital_state: " ",
    hospital_zip_code: " ",
    about_us: " ",
    comments: " ",
  });
  const [loader, setLoader] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [errors, setErrors] = useState({});

  const handleFields = (event) => {
    const value = event.target.value;
    setValues({
      ...values,
      [event.target.name]:
        value === "" ? " " : value.length >= 1 ? value : value.trim(),
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let payload = { ...values };

    payload["hospital_zip_code"] = parseInt(payload["hospital_zip_code"]);
    payload["status"] = "Pending";
    setLoader(true);
    try {
      const res = await addDataApi("Referal", payload);
      if (res.status === 200) {
        setLoader(false);
        setValues({
          ...values,
          user_type: "Provider",
          dr_first_name: " ",
          dr_last_name: " ",
          dr_phone_no: " ",
          dr_email: " ",
          job_role: "none",
          job_title: " ",
          contact_preservence: "none",
          services_interested: "none",
          patient_name: " ",
          hospital_name: " ",
          hospital_address: " ",
          hospital_city: " ",
          hospital_state: " ",
          hospital_zip_code: " ",
          about_us: " ",
          comments: " ",
        });
        setFormSuccess(true);
      }
    } catch (err) {
      setLoader(false);
      if (err.response) {
      }
    }
  };
  const checkNumbers = (event) => {
    // console.log("event ===", event)
    let errors_ = { ...errors };
    if (!/[0-9]/.test(event.key)) {
      // console.log("==== if =====")
      // event.preventDefault();
      errors_["numbers"] = "Enter Numbers Only";
    } else {
      delete errors_["numbers"];
    }
    setErrors(errors_);
  };

  const isButtonDisable = () => {
    if (
      values.user_type !== "Provider" ||
      values.dr_first_name === " " ||
      values.dr_last_name === " " ||
      values.dr_phone_no === " " ||
      values.dr_email === " " ||
      values.job_role === "none" ||
      values.job_title === " " ||
      values.contact_preservence === "none" ||
      values.services_interested === "none" ||
      values.patient_name === " " ||
      values.hospital_name === " " ||
      values.hospital_address === " " ||
      values.hospital_city === " " ||
      values.hospital_address === " " ||
      values.hospital_zip_code === " " ||
      values.about_us === " " ||
      values.comments === " " ||
      errors.email ||
      errors.phone
    ) {
      return true;
    }
    return false;
  };
  const validatePhone = (e) => {
    const regexPhoneNumber =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    let errors_ = { ...errors };
    if (e.target.value.match(regexPhoneNumber)) {
      delete errors_["phone"];
    } else {
      errors_["phone"] = "Invalid phone number.";
    }
    setErrors(errors_);
  };

  const validateEmail = (e) => {
    let errors_ = { ...errors };
    let errorEmail = false;
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(e.target.value)) {
      errors_["email"] = "Invalid email address.";
      errorEmail = true;
    }
    if (errorEmail === false) {
      delete errors_["email"];
    }
    setErrors(errors_);
  };
  return (
    <>
      <div className={!isMobile ? "raferal_main" : "raferal_mainMobile"}>
        <p className="raferal_heading">Start Your Referral Process </p>
      </div>

      <div className="raferal_form_main">
        <div className="raferal_form_data">
          <p className="referal-p-style">
            Executive Infusion responds to referrals quickly and delivers
            coverage 24/7 to support patients and providers.
          </p>

          {formSuccess === true && (
            <Col
              md={12}
              className="d-flex justify-content-center align-items-center"
            >
              <div className="successWrapper">
                <div className="circleBlueDiv">
                  <div>
                    <img src={successWhiteTick} alt="whiteTick" />
                  </div>
                </div>
                <p className="successTitleText">Success</p>
                <p className="successsubTitleText">
                  We'll get back to you shortly!{" "}
                </p>
                <button
                  className="successBtnClass"
                  onClick={() => setFormSuccess(false)}
                >
                  Continue
                </button>
              </div>
            </Col>
          )}
          {formSuccess === false && (
            <div className="raferal_form">
              <Row>
                <Form.Group as={Col} md={6} controlId="formGridFirstName">
                  <ValidateTextField
                    label="Dr. First Name*"
                    name="dr_first_name"
                    value={values.dr_first_name}
                    onChange={handleFields}
                  />
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="formGridLastName">
                  <ValidateTextField
                    label="Dr. Last Name*"
                    name="dr_last_name"
                    value={values.dr_last_name}
                    onChange={handleFields}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md={6} controlId="formGridFirstName">
                  <ValidateTextField
                    label="Dr. Phone Number*"
                    onBlur={validatePhone}
                    name="dr_phone_no"
                    value={values.dr_phone_no}
                    onChange={handleFields}
                  />
                  <div className="text-danger" style={{ marginTop: "-15px" }}>
                    {errors.phone}
                  </div>
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="formGridLastName">
                  <ValidateTextField
                    label="Dr. Email*"
                    onBlur={validateEmail}
                    name="dr_email"
                    value={values.dr_email}
                    onChange={handleFields}
                  />
                  <div className="text-danger" style={{ marginTop: "-15px" }}>
                    {errors.email}
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md={6} controlId="formGridState">
                  <FormControl
                    sx={{
                      minWidth: 120,
                      width: "100%",
                      marginTop: "15px ",
                      marginBottom: "25px",
                    }}
                  >
                    <InputLabel
                      sx={SelectSx}
                      id="demo-simple-select-helper-label"
                    >
                      Job Role*
                    </InputLabel>
                    <Select
                      IconComponent={(props) =>
                        activeSelect === "first" ? (
                          <AvtiveArrowSvg {...props} />
                        ) : (
                          <BlackArrowDown {...props} />
                        )
                      }
                      onFocus={() => setActiveSelect("first")}
                      onBlur={() => setActiveSelect("")}
                      sx={styles.select}
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Job Role*"
                      name="job_role"
                      value={values.job_role}
                      onChange={handleFields}
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem
                        value={"none"}
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                      >
                        Select an option
                      </MenuItem>

                      <MenuItem
                        value={"Case Manager"}
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                      >
                        Case Manager
                      </MenuItem>
                      <MenuItem
                        value={"Discharge Planner"}
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                      >
                        Discharge Planner
                      </MenuItem>
                      <MenuItem
                        value={"Physician"}
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                      >
                        Physician
                      </MenuItem>
                      <MenuItem
                        value={"Other"}
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                      >
                        Other
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Form.Group>

                <Form.Group as={Col} md={6} controlId="formGridLastName">
                  <ValidateTextField
                    label="Job Title*"
                    name="job_title"
                    value={values.job_title}
                    onChange={handleFields}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md={6} controlId="formGridState">
                  <FormControl
                    sx={{
                      minWidth: 120,
                      width: "100%",
                      marginTop: "15px ",
                      marginBottom: "25px",
                    }}
                  >
                    <InputLabel
                      sx={SelectSx}
                      id="demo-simple-select-helper-label"
                    >
                      Contact Preservence*
                    </InputLabel>
                    <Select
                      IconComponent={(props) =>
                        activeSelect === "second" ? (
                          <AvtiveArrowSvg {...props} />
                        ) : (
                          <BlackArrowDown {...props} />
                        )
                      }
                      onFocus={() => setActiveSelect("second")}
                      onBlur={() => setActiveSelect("")}
                      sx={styles.select}
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Contact Preservence*"
                      name="contact_preservence"
                      value={values.contact_preservence}
                      onChange={handleFields}
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem
                        value={"none"}
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                      >
                        Select an option
                      </MenuItem>

                      <MenuItem
                        value={"Email"}
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                      >
                        Email
                      </MenuItem>
                      <MenuItem
                        value={"Phone"}
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                      >
                        Phone
                      </MenuItem>
                      <MenuItem
                        value={"Text"}
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                      >
                        Text
                      </MenuItem>
                      <MenuItem
                        value={"In Person"}
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                      >
                        In Person
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Form.Group>

                <Form.Group as={Col} md={6} controlId="formGridState">
                  <FormControl
                    sx={{
                      minWidth: 120,
                      width: "100%",
                      marginTop: "15px ",
                      marginBottom: "25px",
                    }}
                  >
                    <InputLabel
                      sx={SelectSx}
                      id="demo-simple-select-helper-label"
                    >
                      Services Interested*
                    </InputLabel>
                    <Select
                      IconComponent={(props) =>
                        activeSelect === "third" ? (
                          <AvtiveArrowSvg {...props} />
                        ) : (
                          <BlackArrowDown {...props} />
                        )
                      }
                      onFocus={() => setActiveSelect("third")}
                      onBlur={() => setActiveSelect("")}
                      sx={styles.select}
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Services Interested*"
                      name="services_interested"
                      value={values.services_interested}
                      onChange={handleFields}
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem
                        value={"none"}
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                      >
                        Select an option
                      </MenuItem>

                      <MenuItem
                        value={"Ambulatory Infusion Center Services"}
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                      >
                        Ambulatory Infusion Center Services
                      </MenuItem>
                      <MenuItem
                        value={"Home Infusion Services"}
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                      >
                        Home Infusion Services
                      </MenuItem>
                      <MenuItem
                        value={"Specialty Drugs"}
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                      >
                        Specialty Drugs
                      </MenuItem>
                      <MenuItem
                        value={"Drugs / Infusions"}
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                      >
                       Drugs / Infusions
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} md={6} controlId="formGridFirstName">
                  <ValidateTextField
                    label="Patient Name*"
                    name="patient_name"
                    value={values.patient_name}
                    onChange={handleFields}
                  />
                </Form.Group>

                <Form.Group as={Col} md={6} controlId="formGridLastName">
                  <ValidateTextField
                    label="Hospital / Practice Name*"
                    name="hospital_name"
                    value={values.hospital_name}
                    onChange={handleFields}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md={6} controlId="formGridFirstName">
                  <ValidateTextField
                    label="Hospital / Practice Street Address*"
                    name="hospital_address"
                    value={values.hospital_address}
                    onChange={handleFields}
                  />
                </Form.Group>

                <Form.Group as={Col} md={6} controlId="formGridLastName">
                  <ValidateTextField
                    label="Hospital / Practice City*"
                    name="hospital_city"
                    value={values.hospital_city}
                    onChange={handleFields}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md={6} controlId="formGridState">
                  <ValidateTextField
                    label="Hospital / Practice State*"
                    name="hospital_state"
                    value={values.hospital_state}
                    onChange={handleFields}
                  />
                </Form.Group>

                <Form.Group as={Col} md={6} controlId="formGridLastName">
                  <ValidateTextField
                    label="Hospital / Practice Zip Code*"
                    onKeyPress={checkNumbers}
                    name="hospital_zip_code"
                    value={values.hospital_zip_code}
                    onChange={handleFields}
                  />
                  <div className="text-danger" style={{ marginTop: "-15px" }}>
                    {errors.numbers}
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md={12} controlId="formGridFirstName">
                  <ValidateTextField
                    label="How Did You Hear About Us*"
                    name="about_us"
                    value={values.about_us}
                    onChange={handleFields}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md={12} controlId="formGridFirstName">
                  <ValidateTextField
                    label="Comments*"
                    name="comments"
                    value={values.comments}
                    onChange={handleFields}
                  />
                </Form.Group>
              </Row>
              {/* <button className='raferal_btn'>Submit</button> */}
              {loader === false && (
                <button
                  className={`${
                    isButtonDisable() ? "disableContactBtn" : "ContactBtn"
                  }`}
                  type="submit"
                  disabled={isButtonDisable()}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              )}
              {loader === true && (
                <Loader
                  type="Puff"
                  color="#003b7d"
                  height={50}
                  width={50}
                ></Loader>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Raferal;
