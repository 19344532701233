import React, { useEffect } from "react";
import Header from "../Componenets/Navbar/Navbar";
import Services5 from "../Componenets/Servicess/Services5";
import Testimonial from "../Componenets/Testimonial/Testimonial";
import Contact from "../Componenets/Contact/Contact";
import Footer from "../Componenets/Footer/Footer";
import WellNessDivs from "../Componenets/WellNessDivs/WellNessDivs";
import WellNessGrid from "../Componenets/WellNessGrid/WellNessGrid";
import wellnessDivImage from "../Componenets/assets/images/desktopsub6.jpg";
import { useNavigate } from 'react-router-dom';
const Wellness = ({ reviewData }) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // Redirect to the homepage when the Wellness page is loaded
    navigate('/');
  }, [navigate]);

 
  return (
    <>
      <Header />
      <Services5 title="Book an Appointment" />
      <WellNessDivs
        welltitle1={"Comprehensive"}
        welltitle2={"Range Of Infusions"}
        wellsubtitle={
          "Lead your way to a healthier future with the comprehensive range of  infusions provided by Executive Infusion. These  infusions and drugs help treat chronic and acute conditions in patients requiring infusion therapy."
        }
        wellimage={wellnessDivImage}
      />
      <WellNessGrid />
      {reviewData.length > 0 && <Testimonial reviewData={reviewData} />}
      <Contact />
      <Footer />
    </>
  );
};

export default Wellness;
