import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import "../Contact/contact.css";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormControlLabel, Grid } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ValidateTextField from "../ValidateTextField/ValidateTextField";
import { SelectSx } from "../AppointmentForm/AppointmentForm";
import { styles } from "../Raferal/Raferal.js";
import AvtiveArrowSvg from "../assets/svgs/AvtiveArrowSvg";
import BlackArrowDown from "../assets/svgs/BlackArrowDown";
import successWhiteTick from "../assets/images/successWhiteTick.png";
import { addDataApi } from "../../api";
import Loader from "react-loader-spinner";

const PatientData = [
  "AIC Services",
  // "Wellness Infusions",
  "Compounding",
  "Home Infusion",
];
const ProviderData = ["AIC Services", "Home Infusion Services"];

const Contact = () => {
  const [activeSelect, setActiveSelect] = useState("");
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 ? true : false
  );

  const updateDimensions = () => {
    setIsMobile(window.innerWidth < 768 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isMobile]);
  const [values, setValues] = React.useState({
    user_type: "none",
    provide_service: "none",
    first_name: " ",
    last_name: " ",
    phone_no: " ",
    email: " ",
    comments: " ",
    help_with: [],
  });

  const [loader, setLoader] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorName, setErrorName] = useState("");
  const [errorLast, setErrorLast] = useState("");

  const handleValidation = () => {
    if (values.first_name.trim() === "") {
      setErrorName("Field cannot be empty");
    } else if (/\d/.test(values.first_name)) {
      setErrorName("Field cannot contain numbers");
    } else {
      setErrorName("");
    }
  };
  const handleValidationLast = () => {
    if (values.last_name.trim() === "") {
      setErrorLast("Field cannot be empty");
    } else if (/\d/.test(values.last_name)) {
      setErrorLast("Field cannot contain numbers");
    } else {
      setErrorLast("");
    }
  };
  const handleFields = (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    const newArray = values.help_with.includes(event.target.value)
      ? values.help_with.filter((item) => item !== event.target.value)
      : [...values.help_with, event.target.value];
    console.log(value, value[0]);
    event.target.type === "checkbox"
      ? setValues({
          ...values,
          [event.target.name]: newArray,
        })
      : setValues({
          ...values,
          [event.target.name]:
            value === "" ? " " : value.length >= 1 ? value : value.trim(),
        });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let payload = { ...values };
    payload["help_with"] = payload["help_with"].toString();
    payload["status"] = "Pending";
    setLoader(true);
    try {
      const res = await addDataApi("Need_Helps", payload);
      if (res.status === 200) {
        setLoader(false);
        setValues({
          ...values,
          user_type: "none",
          provide_service: "none",
          first_name: " ",
          last_name: " ",
          phone_no: " ",
          email: " ",
          comments: " ",
          help_with: [],
        });
        setFormSuccess(true);
      }
    } catch (err) {
      setLoader(false);
      if (err.response) {
      }
    }
  };
  const isButtonDisable = () => {
    if (
      values.user_type === "none" ||
      values.provide_service === "none" ||
      values.first_name === " " ||
      values.last_name === " " ||
      values.phone_no === " " ||
      values.email === " " ||
      // values.comments === " " ||
      values.help_with.length === 0 ||
      errors.email ||
      errors.phone
    ) {
      return true;
    }
    return false;
  };
  const validatePhone = (e) => {
    const regexPhoneNumber =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    let errors_ = { ...errors };
    if (e.target.value.match(regexPhoneNumber)) {
      delete errors_["phone"];
    } else {
      errors_["phone"] = "Invalid phone number.";
    }
    e.target.value.length !== 10
      ? (errors_["phone"] = "Use only 10 digit number")
      : delete errors_["phone"];
    setErrors(errors_);
  };

  const validateEmail = (e) => {
    let errors_ = { ...errors };
    let errorEmail = false;
    var pattern = new RegExp(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i
    );
    if (!pattern.test(e.target.value.trim())) {
      errors_["email"] = "Invalid email address.";
      errorEmail = true;
    }
    if (errorEmail === false) {
      delete errors_["email"];
    }
    setErrors(errors_);
  };
  // console.log(values.first_name, "first");
  return (
    <div
      className={` py-4 ${isMobile ? "contactMobile" : "contact"}`}
      id="Contact"
    >
      {formSuccess === true ? (
        <Col
          md={12}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="successWrapper">
            <div className="circleBlueDiv">
              <div>
                <img src={successWhiteTick} alt="whiteTick" />
              </div>
            </div>
            <p className="successTitleText">Success</p>
            <p className="successsubTitleText">
              We'll get back to you shortly!{" "}
            </p>
            <button
              className="successBtnClass"
              onClick={() => setFormSuccess(false)}
            >
              Continue
            </button>
          </div>
        </Col>
      ) : (
        <h3 className="text-center mb-5 text-light contact-title">
          Need Help?
        </h3>
      )}
      {formSuccess === false && (
        <Container className="contact_form_main">
          <Row className="top_dropdwon ">
            <Col md={6} className="form-con rowStyleing ">
              <Form>
                <Form.Group>
                  <FormControl sx={{ minWidth: 120, width: "100%" }}>
                    <InputLabel sx={SelectSx} id="demo-simple-select-label">
                      I'm a
                    </InputLabel>
                    <Select
                      IconComponent={(props) =>
                        activeSelect === "eight" ? (
                          <AvtiveArrowSvg {...props} />
                        ) : (
                          <BlackArrowDown {...props} />
                        )
                      }
                      onFocus={() => setActiveSelect("eight")}
                      onBlur={() => setActiveSelect("")}
                      sx={styles.select}
                      defaultValue={"none"}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.user_type}
                      label="I'm a"
                      name="user_type"
                      onChange={handleFields}
                      fullWidth
                      displayEmpty
                      // className="MuiSelect-select"
                    >
                      {/* <MenuItem
                        value={"none"}
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                      >
                        Select an option
                      </MenuItem> */}
                      <MenuItem
                        value={"none"}
                        disabled
                        hidden
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                      >
                        Select an option
                      </MenuItem>

                      <MenuItem
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                        value={"Patient"}
                      >
                        Patient
                      </MenuItem>
                      <MenuItem
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                        value={"Provider"}
                      >
                        Provider
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Form.Group>
              </Form>
            </Col>
            <Col md={6} className="form-con rowStyleing">
              <Form>
                <Form.Group>
                  <FormControl
                    sx={{
                      minWidth: 120,
                      width: "100%",
                      marginTop: `${isMobile ? "36px" : "0px"} `,
                    }}
                  >
                    <InputLabel
                      sx={SelectSx}
                      id="demo-simple-select-helper-label"
                    >
                      and would like to get
                    </InputLabel>
                    <Select
                      IconComponent={(props) =>
                        activeSelect === "nine" ? (
                          <AvtiveArrowSvg {...props} />
                        ) : (
                          <BlackArrowDown {...props} />
                        )
                      }
                      onFocus={() => setActiveSelect("nine")}
                      onBlur={() => setActiveSelect("")}
                      sx={styles.select}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.provide_service}
                      name="provide_service"
                      label="and would like to get"
                      onChange={handleFields}
                      displayEmpty
                      disabled={values.user_type === "none" ? true : false}
                    >
                      <MenuItem
                        value={"none"}
                        disabled
                        hidden
                        onMouseEnter={(e) => {
                          e.target.style.color = "white";
                          e.target.style.backgroundColor = "#25ABDF";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#3A3A3A";
                          e.target.style.backgroundColor = "#FFFFFF";
                        }}
                      >
                        Select an option
                      </MenuItem>

                      {values.user_type === "Provider"
                        ? [
                            PatientData.map((topic, i) => (
                              <MenuItem
                                onMouseEnter={(e) => {
                                  e.target.style.color = "white";
                                  e.target.style.backgroundColor = "#25ABDF";
                                }}
                                onMouseLeave={(e) => {
                                  e.target.style.color = "#3A3A3A";
                                  e.target.style.backgroundColor = "#FFFFFF";
                                }}
                                key={i}
                                value={topic}
                              >
                                {topic}
                              </MenuItem>
                            )),
                          ]
                        : [
                            ProviderData.map((topic, i) => (
                              <MenuItem
                                onMouseEnter={(e) => {
                                  e.target.style.color = "white";
                                  e.target.style.backgroundColor = "#25ABDF";
                                }}
                                onMouseLeave={(e) => {
                                  e.target.style.color = "#3A3A3A";
                                  e.target.style.backgroundColor = "#FFFFFF";
                                }}
                                key={i}
                                value={topic}
                              >
                                {topic}
                              </MenuItem>
                            )),
                          ]}
                    </Select>
                  </FormControl>
                </Form.Group>
              </Form>
            </Col>

            {values.user_type !== "none" &&
              values.provide_service !== "none" && (
                <Row className="mt-5 rowStyling1">
                  <Col md={12} className="">
                    <Form
                      className="contact_form  rounded-3"
                      onSubmit={handleSubmit}
                    >
                      <p className="footer_link mb-4">
                        Drop us a message and we will get back to you!
                      </p>
                      <Row className="mt-4">
                        <Form.Group
                          as={Col}
                          md={6}
                          className="rowStyleing"
                          controlId="formGridFirstName"
                        >
                          <ValidateTextField
                            type="text"
                            label="First Name*"
                            name="first_name"
                            value={values.first_name}
                            onChange={handleFields}
                            onBlur={handleValidation}
                          />
                          {errorName && (
                            <div
                              className="text-danger"
                              style={{ marginTop: "-15px" }}
                            >
                              {errorName}
                            </div>
                          )}
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md={6}
                          className="rowStyleing"
                          controlId="formGridLastName"
                        >
                          <ValidateTextField
                            type="text"
                            label="Last Name*"
                            name="last_name"
                            value={values.last_name}
                            onChange={handleFields}
                            onBlur={handleValidationLast}
                          />
                          {errorLast && (
                            <div
                              className="text-danger"
                              style={{ marginTop: "-15px" }}
                            >
                              {errorLast}
                            </div>
                          )}
                        </Form.Group>
                      </Row>
                      <Row className="">
                        <Form.Group
                          as={Col}
                          md={6}
                          className="rowStyleing"
                          controlId="formGridPhoneNumber"
                        >
                          <ValidateTextField
                            onBlur={validatePhone}
                            label="Phone Number*"
                            name="phone_no"
                            value={values.phone_no}
                            onChange={handleFields}
                            type="number"
                          />
                          <p className="helper-para"><span>Note:</span> Reply STOP to opt-out </p>
                          <div
                            className="text-danger"
                            style={{ marginTop: "-15px" }}
                          >
                            {errors.phone}
                          </div>
                          
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md={6}
                          className="rowStyleing mobileMarginCls"
                          controlId="formGridPasswordEmail"
                        >
                          <ValidateTextField
                            onBlur={validateEmail}
                            label="Email*"
                            name="email"
                            value={values.email}
                            onChange={handleFields}
                          />
                          <div
                            className="text-danger"
                            style={{ marginTop: "-15px" }}
                          >
                            {errors.email}
                          </div>
                        </Form.Group>
                      </Row>
                      <Row className="marginTopClass">
                        <Form.Group
                          as={Col}
                          lg={12}
                          md={6}
                          className="mb-3 col-md-12 rowStyleing"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <ValidateTextField
                            label="Comments (optional)"
                            name="comments"
                            value={values.comments}
                            onChange={handleFields}
                            rows={4}
                          />
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md={6}
                          lg={12}
                          className="mb-3 col-md-12"
                          id="formGridCheckbox"
                        >
                          <Form.Label className="footer_link_heading">
                            I'm looking for help with...*
                          </Form.Label>
                          <Row className="">
                            <Col md={4} sm={6} xs={6} className="rowStyleing">
                              <FormControlLabel
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  color: "#000000",
                                }}
                                label="Infusion Therapy"
                                strokeLinejoin="center"
                                control={<Checkbox />}
                                name="help_with"
                                value="Infusion Therapy"
                                checked={values.help_with.includes(
                                  "Infusion Therapy"
                                )}
                                onChange={handleFields}
                              />
                            </Col>
                            <Col md={4} sm={6} xs={6} className="rowStyling1">
                              <FormControlLabel
                                label="Medications"
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  color: "#000000",
                                }}
                                control={<Checkbox />}
                                name="help_with"
                                value="Medications"
                                checked={values.help_with.includes(
                                  "Medications"
                                )}
                                onChange={handleFields}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4} sm={6} xs={6} className="rowStyleing">
                              <FormControlLabel
                                label="Patient Care"
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  color: "#000000",
                                }}
                                control={<Checkbox />}
                                checked={values.help_with.includes(
                                  "Patient Care"
                                )}
                                value="Patient Care"
                                name="help_with"
                                onChange={handleFields}
                              />
                            </Col>
                            <Col md={4} sm={6} xs={6} className="rowStyling1">
                              <FormControlLabel
                                label="Referring Patients"
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  color: "#000000",
                                }}
                                control={<Checkbox />}
                                name="help_with"
                                value="Referring Patients"
                                checked={values.help_with.includes(
                                  "Referring Patients"
                                )}
                                onChange={handleFields}
                              />
                            </Col>
                          </Row>
                          <Col md={4} className=" mt-4">
                            {loader === false && (
                              <button
                                className={`${
                                  isButtonDisable()
                                    ? "disableContactBtn"
                                    : "ContactBtn"
                                }`}
                                type="submit"
                                disabled={isButtonDisable()}
                                onClick={handleSubmit}
                              >
                                Submit
                              </button>
                            )}
                            {loader === true && (
                              <Loader
                                type="Puff"
                                color="#003b7d"
                                height={50}
                                width={50}
                              ></Loader>
                            )}
                          </Col>
                        </Form.Group>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              )}
          </Row>
        </Container>
      )}
    </div>
  );
};

export default Contact;
