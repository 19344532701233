import React, { useEffect, useState } from "react";
import "../Servicess/services.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate, useLocation } from "react-router-dom";
import file6 from "../assets/files/Form 6 (Wellness Infusions Order Form).pdf-.pdf";
AOS.init();
const Services5 = ({ title }) => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 ? true : false
  );
  const updateDimensions = () => {
    setIsMobile(window.innerWidth < 768 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isMobile]);
  let navigate = useNavigate();
  const location = useLocation();
  const navigate_ = (url) => {
    navigate(url);
  };

  const url = location.pathname;
  return (
    <>
   
    </>
  );
};

export default Services5;
